import { AdminAppRoles, CognitoLoginDto } from '@innerwell/dtos';
import { useMutation } from '@tanstack/react-query';
import { Route } from 'next';
import { useRouter, useSearchParams } from 'next/navigation';

import useThemedToast from '@/hooks/useThemedToast';

import { adminApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';

export const useLogin = () => {
   const { toastSuccess, toastError } = useThemedToast();
   const { push } = useRouter();
   const searchParams = useSearchParams();
   const callbackUrl = searchParams.get('callbackUrl') as Route | null;

   const { refetchSession } = useSession();

   return useMutation({
      mutationFn: (data: CognitoLoginDto) => {
         return adminApiClient.authenticate.login({
            body: data,
            extraHeaders: {
               dontcheckcredentials: 'true',
            },
         });
      },

      onError: () => {
         toastError('Invalid email or password');
      },

      onSuccess: async () => {
         const session = await refetchSession();

         toastSuccess('Successfully signed in');

         if (session?.role === AdminAppRoles.Admin) {
            push(callbackUrl ?? '/clinicians');
         } else {
            push('/patients');
         }
      },
   });
};
