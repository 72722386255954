'use client';

import { Button, Heading, Input } from '@chakra-ui/react';
import { useSearchParams } from 'next/navigation';
import React, { useEffect } from 'react';
import { z } from 'zod';

import { useLogin } from '@/hooks/react-query/useLogin';
import useThemedToast from '@/hooks/useThemedToast';
import { useZodForm } from '@/hooks/useZodForm';

import CustomFormControl from '@/components/Devkit/CustomFormControl/CustomFormControl';
import PasswordInput from '@/components/Devkit/PasswordInput/PasswordInput';

const loginSchema = z.object({
   email: z
      .string()
      .min(1, { message: 'Email is required' })
      .email({ message: 'The email format is incorrect.' }),
   password: z.string().min(1, { message: 'Password is required' }),
});

type LoginSchema = z.infer<typeof loginSchema>;

export const LoginPage = () => {
   const searchParams = useSearchParams();
   const queryEmail = searchParams.get('email');
   const queryError = searchParams.get('error');

   const { toastSuccess } = useThemedToast();

   const {
      handleSubmit,
      register,
      setValue,
      formState: { errors },
   } = useZodForm({
      schema: loginSchema,
   });

   useEffect(() => {
      if (queryEmail) {
         setValue('email', queryEmail);
      }
   }, [queryEmail, setValue]);

   React.useEffect(() => {
      if (queryError === 'SessionRequired') {
         toastSuccess('Please sign in to continue using the Innerwell Portal.');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const { mutate: login, isPending: isSubmitting } = useLogin();

   const onSubmit = async (values: LoginSchema) => {
      login({
         username: values.email,
         password: values.password,
      });
   };

   return (
      <>
         <Heading as="h1" textAlign="center" mb={{ base: 6, lg: 10 }}>
            Admin Panel
         </Heading>

         <form onSubmit={handleSubmit(onSubmit)}>
            <CustomFormControl
               label="Email"
               name="email"
               isInvalid={!!errors.email}
               errorMsg={errors.email && (errors.email.message as string)}
               mode="dark"
            >
               <Input
                  id="email"
                  {...register('email')}
                  placeholder="Enter your email address"
                  colorScheme="white"
               />
            </CustomFormControl>

            <CustomFormControl
               label="Password"
               name="password"
               isInvalid={!!errors.password}
               errorMsg={errors.password && (errors.password.message as string)}
               mode="dark"
            >
               <PasswordInput
                  id="password"
                  {...register('password')}
                  placeholder="Enter your password"
                  isInvalid={!!errors.password}
                  colorScheme="white"
               />
            </CustomFormControl>

            <Button
               type="submit"
               mt={{ base: 4, lg: 6 }}
               isLoading={isSubmitting}
               w={{ base: 'full', lg: 'full' }}
            >
               Login
            </Button>
         </form>
      </>
   );
};
