import {
   Flex,
   IconButton,
   Input,
   InputGroup,
   InputRightElement,
   List,
   ListItem,
} from '@chakra-ui/react';
import { InputProps } from '@chakra-ui/react';
import React from 'react';

import { Icon } from '@/components/Icon';

type ValidationSchema = {
   name: string;
   valid: boolean;
   message: string;
   regex: RegExp;
};

const initialValidationSchema: ValidationSchema[] = [
   {
      name: 'minCharLength',
      valid: false,
      message: '8 characters minimum',
      regex: /^(?=.{8,})/,
   },
   {
      name: 'hasNumber',
      valid: false,
      message: 'One number',
      regex: /^(?=.*\d)/,
   },
   {
      name: 'hasSpecialChar',
      valid: false,
      message: 'One special character',
      regex: /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/,
   },
   {
      name: 'hasUppercase',
      valid: false,
      message: 'One uppercase letter',
      regex: /[A-Z]/,
   },
   {
      name: 'hasLowercase',
      valid: false,
      message: 'One lowercase letter',
      regex: /[a-z]/,
   },
];

const PasswordInput = React.forwardRef<
   HTMLInputElement,
   Omit<InputProps, 'type'> & {
      variant?: 'dark' | 'light';
      showExtraValidation?: boolean;
   }
>(({ variant = 'dark', showExtraValidation = false, ...props }, ref) => {
   const [input, setInput] = React.useState('');
   const [passwordValidation, setPasswordValidation] = React.useState<
      ValidationSchema[]
   >(initialValidationSchema);

   const [isInputVisited, setIsInputVisited] = React.useState(false);
   const [show, setShow] = React.useState(false);
   const handlePasswordVisibilityClick = () => setShow(!show);
   const handleInputFocus = () => setIsInputVisited(true);
   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (showExtraValidation) {
         setPasswordValidation((prev) => {
            return prev.map((validation) => ({
               ...validation,
               valid: validation.regex.test(value),
            }));
         });
      }

      if (props.onChange) {
         props.onChange(e);
      }

      setInput(value);
   };

   const { isInvalid } = props;

   return (
      <InputGroup display="flex" flexDir="column">
         <Input
            ref={ref}
            id="password"
            type={show ? 'text' : 'password'}
            pr="5rem"
            {...props}
            value={input}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
         />
         <InputRightElement width="36px" m={1} mr={isInvalid ? 10 : 1}>
            <IconButton
               aria-label={show ? 'Hide password' : 'Show password'}
               display="flex"
               icon={
                  show ? (
                     <Icon w={4} h={4} name="eyes-open" fill="text.primary" />
                  ) : (
                     <Icon w={4} h={4} name="eyes-close" fill="text.primary" />
                  )
               }
               variant="link"
               onClick={handlePasswordVisibilityClick}
            ></IconButton>
         </InputRightElement>
         {isInputVisited && showExtraValidation && (
            <List my={2}>
               {passwordValidation.map((validation) => {
                  const { name, valid, message } = validation;

                  return (
                     <ListItem
                        key={name}
                        as={Flex}
                        alignItems="center"
                        gap={1}
                        fontSize="xxs"
                        color={
                           valid
                              ? variant === 'light'
                                 ? 'white'
                                 : 'background.primary'
                              : variant === 'light'
                                ? 'alert.primary'
                                : 'alert.tertiary'
                        }
                        fontWeight={500}
                        transition="all .5s ease"
                     >
                        {valid ? (
                           <Icon name="checkmark" w={3.5} h={3.5} />
                        ) : (
                           <Icon name="close" w={3.5} h={3.5} />
                        )}
                        {message}
                     </ListItem>
                  );
               })}
            </List>
         )}
      </InputGroup>
   );
});

PasswordInput.displayName = '';

export default PasswordInput;
